<template>
  <nav id="navbar" class="navbar is-fixed-top">
    <div class="header columns is-vcentered is-marginless">
      <router-link v-if="logoUri" to="/" class="column is-narrow" aria-label="Home">
        <img class="logo" :src="logoUri" alt="Directory Hero">
      </router-link>
      <div v-if="directoryCount > 1" class="is-divider-vertical is-hidden-mobile" />
      <div
        v-if="directoryCount > 1"
        id="directorySelector"
        class="column is-narrow is-paddingless is-hidden-mobile pl-3"
      >
        <ais-clear-refinements>
          <div slot-scope="{ refine }" class="select is-fullwidth">
            <select name="section" @change="navigateDirectory($event, refine)">
              <option>I'm looking for...</option>
              <option
                v-for="directory in directories"
                :key="directory.id"
                :selected="currentDirectory.slug.toLowerCase() == directory.slug.toLowerCase()"
                :value="directory.slug"
              >
                {{ directory.name }}
              </option>
            </select>
          </div>
        </ais-clear-refinements>
      </div>
      <LeaderboardAd />
    </div>
    <div
      id="facet-nav"
      class="columns is-vcentered is-marginless"
      :class="{ 'is-hidden': routeRecord }"
    >
      <div class="column is-narrow is-paddingless is-hidden-touch">
        <FacetNav />
      </div>
      <div class="column">
        <div class="field">
          <p class="control has-icons-left">
            <ais-search-box
              :class-names="{
                'ais-SearchBox-input': 'input searchBox',
                'ais-SearchBox-form': 'form',
                'ais-SearchBox-submit': 'is-hidden',
                'ais-SearchBox-submitIcon': 'is-hidden',
                'ais-SearchBox-reset': 'is-hidden',
                'ais-SearchBox-resetIcon': 'is-hidden',
              }"
              :placeholder="`Search ${currentDirectory.name} by Name, ${facetNames}`"
              aria-label="Search"
              autofocus
            />
            <span class="icon has-text-grey is-left is-large">
              <fa icon="search" size="lg" full-width />
            </span>
          </p>
        </div>
      </div>
      <div class="column is-narrow has-text-right is-hidden-touch">
        <ais-state-results>
          <template slot-scope="{ results: { nbHits } }">
            <span>{{ nbHits.toLocaleString() }} Results</span>
          </template>
        </ais-state-results>
      </div>
    </div>
  </nav>
</template>

<script>
import FacetNav from '@/components/SearchResults/FacetNav.vue';
import LeaderboardAd from '@/components/Ads/LeaderboardAd.vue';
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
      directoryCount: 'directory/count',
      facets: 'fields/facets',
    }),
    ...mapState({
      logoUri: (state) => state.logo_uri,
      directories: (state) => state.directories,
      routeRecord: (state) => state.route.params.record,
    }),
    facetNames() {
      return this.facets.map((facet) => facet.name).join(', ');
    },
  },
  methods: {
    navigateDirectory(e, refine) {
      refine();

      this.$router.push({
        name: 'directory',
        query: null,
        params: { directory: e.target.value.toLowerCase() },
      });
    },
  },
  components: {
    FacetNav,
    LeaderboardAd,
  },
};
</script>
